import { adminPlaylistActionTypes } from './Constants';

export function getPlaylistRequest(playlistId, questionId, showLoading = true) {
	return {
		type: adminPlaylistActionTypes.GET_PLAYLIST_REQUEST,
		payload: { playlistId, questionId, showLoading },
	};
}

export function getPlaylistSuccess(data) {
	return {
		type: adminPlaylistActionTypes.GET_PLAYLIST_SUCCESS,
		payload: { data },
	};
}

export function getPlaylistError() {
	return {
		type: adminPlaylistActionTypes.GET_PLAYLIST_ERROR,
	};
}

export function managePlaylistRequest(formData, playlistId, questionId) {
	return {
		type: adminPlaylistActionTypes.MANAGE_PLAYLIST_REQUEST,
		payload: { formData, playlistId, questionId },
	};
}

export function managePlaylistSuccess() {
	return {
		type: adminPlaylistActionTypes.MANAGE_PLAYLIST_SUCCESS,
	};
}

export function managePlaylistError(error) {
	return {
		type: adminPlaylistActionTypes.MANAGE_PLAYLIST_ERROR,
		payload: { error },
	};
}

export function setQuestionsList(data) {
	return {
		type: adminPlaylistActionTypes.SET_QUESTIONS_LIST,
		payload: { data },
	};
}

export function getQuestion(id, blockUpdate) {
	return {
		type: adminPlaylistActionTypes.GET_QUESTION_REQUEST,
		payload: { id, blockUpdate },
	};
}

export function getQuestionSuccess(data) {
	return {
		type: adminPlaylistActionTypes.GET_QUESTION_SUCCESS,
		payload: { data },
	};
}

export function getQuestionError() {
	return {
		type: adminPlaylistActionTypes.GET_QUESTION_ERROR,
	};
}

export function createQuestion(data) {
	return {
		type: adminPlaylistActionTypes.CREATE_QUESTION_REQUEST,
		payload: { data },
	};
}

export function createQuestionSuccess(data) {
	return {
		type: adminPlaylistActionTypes.CREATE_QUESTION_SUCCESS,
		payload: { data },
	};
}

export function createQuestionError() {
	return {
		type: adminPlaylistActionTypes.CREATE_QUESTION_ERROR,
	};
}

export function updateQuestion(id, formData, index, updateSideEffect, tab) {
	return {
		type: adminPlaylistActionTypes.UPDATE_QUESTION_REQUEST,
		payload: { id, formData, index, updateSideEffect, tab },
	};
}

export function updateQuestionSuccess(data) {
	return {
		type: adminPlaylistActionTypes.UPDATE_QUESTION_SUCCESS,
		payload: { data },
	};
}

export function updateQuestionError() {
	return {
		type: adminPlaylistActionTypes.UPDATE_QUESTION_ERROR,
	};
}

export function manageQuestion(id, type, index) {
	return {
		type: adminPlaylistActionTypes.MANAGE_QUESTION_REQUEST,
		payload: { id, type, index },
	};
}

export function manageQuestionSuccess() {
	return {
		type: adminPlaylistActionTypes.MANAGE_QUESTION_SUCCESS,
	};
}

export function manageQuestionError() {
	return {
		type: adminPlaylistActionTypes.MANAGE_QUESTION_ERROR,
	};
}

export function changeQuestionPosition(data) {
	return {
		type: adminPlaylistActionTypes.CHANGE_QUESTION_POSITION,
		payload: { data },
	};
}

export function previewPlaylistRequest(playlistId, questionId) {
	return {
		type: adminPlaylistActionTypes.PREVIEW_PLAYLIST_REQUEST,
		payload: { playlistId, questionId },
	};
}

export function previewPlaylistSuccess(data) {
	return {
		type: adminPlaylistActionTypes.PREVIEW_PLAYLIST_SUCCESS,
		payload: { data },
	};
}

export function previewPlaylistError() {
	return {
		type: adminPlaylistActionTypes.PREVIEW_PLAYLIST_ERROR,
	};
}

export function publishPlaylistRequest(playlistId, questionId) {
	return {
		type: adminPlaylistActionTypes.PUBLISH_PLAYLIST_REQUEST,
		payload: { playlistId, questionId },
	};
}

export function publishPlaylistSuccess(data) {
	return {
		type: adminPlaylistActionTypes.PUBLISH_PLAYLIST_SUCCESS,
		payload: { data },
	};
}

export function revertTextRequest(textId, playlistId, questionId) {
	return {
		type: adminPlaylistActionTypes.REVERT_TEXT_REQUEST,
		payload: { textId, playlistId, questionId },
	};
}

export function revertTextSuccess() {
	return {
		type: adminPlaylistActionTypes.REVERT_TEXT_SUCCESS,
	};
}

export function revertTextError() {
	return {
		type: adminPlaylistActionTypes.REVERT_TEXT_ERROR,
	};
}

export function publishPlaylistError() {
	return {
		type: adminPlaylistActionTypes.PUBLISH_PLAYLIST_ERROR,
	};
}

export function selectPlaylistQuestion(data) {
	return {
		type: adminPlaylistActionTypes.SELECT_PLAYLIST_QUESTION,
		payload: { data },
	};
}

export function setUpdateTime(data) {
	return {
		type: adminPlaylistActionTypes.SET_UPDATE_TIME,
		payload: { data },
	};
}

export function clearPlaylistState() {
	return {
		type: adminPlaylistActionTypes.CLEAR_PLAYLIST_STATE,
	};
}

export function createQuestionInstruction(playlistId, questionId, data) {
	return {
		type: adminPlaylistActionTypes.CREATE_QUESTION_INSTRUCTION_REQUEST,
		payload: { playlistId, questionId, data },
	};
}

export function createQuestionInstructionSuccess() {
	return {
		type: adminPlaylistActionTypes.CREATE_QUESTION_INSTRUCTION_SUCCESS,
	};
}

export function createQuestionInstructionError() {
	return {
		type: adminPlaylistActionTypes.CREATE_QUESTION_INSTRUCTION_ERROR,
	};
}

export function updateQuestionInstruction(playlistId, questionId, instructionId, data) {
	return {
		type: adminPlaylistActionTypes.UPDATE_QUESTION_INSTRUCTION_REQUEST,
		payload: { playlistId, questionId, instructionId, data },
	};
}

export function updateQuestionInstructionSuccess() {
	return {
		type: adminPlaylistActionTypes.UPDATE_QUESTION_INSTRUCTION_SUCCESS,
	};
}

export function updateQuestionInstructionError() {
	return {
		type: adminPlaylistActionTypes.UPDATE_QUESTION_INSTRUCTION_ERROR,
	};
}

export function deleteQuestionInstruction(playlistId, questionId, instructionId) {
	return {
		type: adminPlaylistActionTypes.DELETE_QUESTION_INSTRUCTION_REQUEST,
		payload: { playlistId, questionId, instructionId },
	};
}

export function deleteQuestionInstructionSuccess() {
	return {
		type: adminPlaylistActionTypes.DELETE_QUESTION_INSTRUCTION_SUCCESS,
	};
}

export function deleteQuestionInstructionError() {
	return {
		type: adminPlaylistActionTypes.DELETE_QUESTION_INSTRUCTION_ERROR,
	};
}

export function toggleInstructionAudioModal(data) {
	return {
		type: adminPlaylistActionTypes.TOGGLE_INSTRUCTION_AUDIO_MODAL,
		payload: { data },
	};
}

export function generateInstructionAudioRequest(playlistId, questionId, messageIds) {
	return {
		type: adminPlaylistActionTypes.GENERATE_INSTRUCTION_AUDIO_REQUEST,
		payload: { playlistId, questionId, messageIds },
	};
}

export function generateInstructionAudioSuccess() {
	return {
		type: adminPlaylistActionTypes.GENERATE_INSTRUCTION_AUDIO_SUCCESS,
	};
}

export function generateInstructionAudioError() {
	return {
		type: adminPlaylistActionTypes.GENERATE_INSTRUCTION_AUDIO_ERROR,
	};
}

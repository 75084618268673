import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import appPushNotifications from './components/appPushNotification/Reducer';
import errorBoundaryReducer from './components/errorBoundary/Reducer';
import errorHandlerReducer from './components/errorHandler/Reducer';
import addImageModalReducer from './components/modals/addImage/Reducer';
import chooseProfileImageModalReducer from './components/modals/chooseProfileImageModal/Reducer';
import confirmationReducer from './components/modals/confirmation/Reducer';
import imagePreviewReducer from './components/modals/imagePreview/Reducer';
import payInvoiceModalReducer from './components/modals/payInvoice/Reducer';
import switchPackageModalReducer from './components/modals/switchPackage/Reducer';
import shareVideoModalReducer from './components/modals/shareVideo/Reducer';
import videoModalReducer from './components/modals/videoModal/Reducer';
import notificationReducer from './components/notification/Reducer';
import notificationsReducer from './components/notifications/Reducer';
import playButtonReducer from './components/playButton/Reducer';
import sidebarReducer from './components/sideBar/Reducer';
import activationCodeReducer from './pages/activationCode/Reducer';
import couponReducer from './pages/couponReader/Reducer';
import forgotPasswordReducer from './pages/forgotPassword/Reducer';
import languageSelectorReducer from './components/languageSelector/Reducer';
import packagesReducer from './pages/packages/Reducer';
import parentShopReducer from './pages/publicShop/Reducer';
import paymentDetailsReducer from './pages/successPayment/Reducer';
import profileReducer from './pages/profile/Reducer';
import resetPasswordReducer from './pages/resetPassword/Reducer';
import searchReducer from './pages/search/Reducer';
import searchDetailsReducer from './pages/searchDetails/Reducer';
import shopReducer from './pages/shop/Reducer';
import signInReducer from './pages/signIn/Reducer';
import signUpReducer from './pages/signUp/Reducer';
import exerciseReducer from './pages/exercise/Reducer';
import sharedReducer from './shared/Reducer';
import onboardingReducer from './components/onboarding/Reducer';
import reportReducer from './pages/report/Reducer';
import goalSettingsReducer from './pages/goalSettings/Reducer';
import learningPathReducer from './pages/learningPath/Reducer';
import dailyGoalReducer from './pages/dailyGoal/Reducer';
import stickersOnboardingReducer from './pages/stickersOnboarding/Reducer';
import difficultyModalReducer from './components/modals/difficulty/Reducer';
import dailyReportReducer from './components/dailyReport/Reducer';
import activityReducer from './pages/activity/Reducer';

const rootReducer = (history) => {
	return {
		activationCodeReducer,
		addImageModalReducer,
		appPushNotifications,
		chooseProfileImageModalReducer,
		confirmationReducer,
		couponReducer,
		errorBoundaryReducer,
		errorHandlerReducer,
		exerciseReducer,
		forgotPasswordReducer,
		form: formReducer,
		imagePreviewReducer,
		languageSelectorReducer,
		notificationReducer,
		notificationsReducer,
		packagesReducer,
		parentShopReducer,
		payInvoiceModalReducer,
		paymentDetailsReducer,
		playButtonReducer,
		profileReducer,
		resetPasswordReducer,
		router: connectRouter(history),
		searchDetailsReducer,
		searchReducer,
		shareVideoModalReducer,
		videoModalReducer,
		sharedReducer,
		shopReducer,
		sidebarReducer,
		signInReducer,
		signUpReducer,
		switchPackageModalReducer,
		onboardingReducer,
		reportReducer,
		goalSettingsReducer,
		learningPathReducer,
		dailyGoalReducer,
		stickersOnboardingReducer,
		difficultyModalReducer,
		dailyReportReducer,
		activityReducer,
	};
};

export default rootReducer;

import { put, takeLatest, all, select, call } from 'redux-saga/effects';
import { SHOW_ONBOARDING_TILE, learningPathActionTypes } from './Constants';
import { getSteps, getUserId, getLearningPathOnboarded } from './Selectors';
import { processRequest } from '../../services/Api';
import { setCookie } from '../../services/Helper';
import { historyPush } from '../../services/History';
import { addDailyGoalTiles, addOnboardingTile, isDailyGoal, isEmptyLearningPath } from './Helper';
import { STICKER_REWARDS } from '../../components/stickers/Constants';
import * as learningPathActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as sharedActions from '../../shared/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			learningPathActionTypes.GET_LEARNING_PATH_REQUEST,
			handleGetLearningPathRequest,
		),
		yield takeLatest(
			learningPathActionTypes.SEND_VIDEO_MODAL_REQUEST,
			handleSendVideoModalRequest,
		),
		yield takeLatest(
			learningPathActionTypes.FINISH_LEARNING_PATH_SECTION_REQUEST,
			handleFinishLearningPathSectionRequest,
		),
		yield takeLatest(
			learningPathActionTypes.SEND_COLLECTED_REWARD_REQUEST,
			handleSendCollectedRewardRequest,
		),
	]);
}

export function* handleGetLearningPathRequest() {
	try {
		const learningPathOnboarded = yield select(getLearningPathOnboarded);

		if (!learningPathOnboarded) {
			const expires = new Date();
			expires.setDate(expires.getDate() + 1);
			expires.setHours(0, 0, 0, 0);

			setCookie(SHOW_ONBOARDING_TILE, true, { expires });
		}

		let learningPathResponse = [];

		const response1 = yield call(processRequest, 'user_learning_paths');
		learningPathResponse = response1.data;

		if (isEmptyLearningPath(learningPathResponse?.learning_path)) {
			yield call(processRequest, 'user_goals/generate_path', 'POST');
			const response2 = yield call(processRequest, 'user_learning_paths');
			learningPathResponse = response2.data;
		}

		if (isEmptyLearningPath(learningPathResponse?.learning_path)) {
			yield put(errorHandlerActions.handleError());
			return;
		}

		learningPathResponse.learning_path = addDailyGoalTiles(learningPathResponse.learning_path);
		learningPathResponse.learning_path = addOnboardingTile(
			learningPathResponse.learning_path,
			learningPathOnboarded,
		);

		yield put(learningPathActions.getLearningPathSuccess(learningPathResponse));
	} catch (e) {
		if (e.response.status === 404) {
			yield call(historyPush, '/doel-instellen-nieuw');
		} else {
			yield put(errorHandlerActions.handleError(e));
		}
		yield put(learningPathActions.getLearningPathError());
	}
}

export function* handleSendVideoModalRequest() {
	try {
		const userId = yield select(getUserId);

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: { learning_path_onboarded: true },
		});

		yield put(sharedActions.getUserDataRequest());
		yield put(learningPathActions.completeOnboardingStep());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleSendCollectedRewardRequest({ payload }) {
	try {
		const { id, body, rewardType } = payload || {};

		yield call(processRequest, `user_stickers/${id}/collect_reward`, 'POST', body);

		const { data } = yield call(processRequest, 'user_learning_paths');

		data.learning_path = addDailyGoalTiles(data.learning_path);
		data.learning_path = addOnboardingTile(data.learning_path);

		if (rewardType === STICKER_REWARDS.SHORTER_LEARNING_PATH) {
			yield put(learningPathActions.setNewSteps(data.learning_path));
			yield put(learningPathActions.setShorterLearningPathAnimation(true));
		} else if (rewardType === STICKER_REWARDS.SKIP_TILE) {
			yield put(learningPathActions.getLearningPathSuccess(data));
			yield put(learningPathActions.setSkipTileAnimation(true));
		} else {
			yield put(learningPathActions.getLearningPathSuccess(data));
			yield put(learningPathActions.setFinishTileAnimation(true));
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleFinishLearningPathSectionRequest({ payload }) {
	const { sectionId, callback } = payload || {};
	try {
		const steps = yield select(getSteps);
		const isDailyGoalTile = isDailyGoal(sectionId, steps);

		const { data } = yield call(
			processRequest,
			`user_learning_path_sections/${sectionId}/finish`,
			'POST',
			isDailyGoalTile ? { daily_goal: true } : null,
		);

		if (data.learning_path_updated) {
			yield put(learningPathActions.setLearningPathUpdated(true));
		}

		yield put(learningPathActions.finishLearningPathSectionSuccess());
		yield put(learningPathActions.setFinishTileAnimation(true));

		callback && callback();
	} catch (e) {
		yield put(learningPathActions.finishLearningPathSectionError());
		yield put(errorHandlerActions.handleError(e, 'page-blocking'));
	}
}

import { sharedTypes } from './Constants';
import i18n from 'i18next';

const initialState = {
	loading: false,
	userData: {
		books: [],
		groups: [],
		folders: [],
		subjects: [],
		canManageBooks: false,
		canAccessNextPackages: false,
	},
	pageSource: '',
	triggerMessagePresent: false,
	translationsInstance: i18n.t,
	backgroundColor: null,
	backgroundPattern: null,
};

export default function sharedReducer(state = initialState, action) {
	const { type, payload } = action;
	const { backgroundColor, backgroundPattern, data, translationsInstance, userData } =
		payload || {};

	switch (type) {
		case sharedTypes.GET_USER_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};

		case sharedTypes.GET_USER_DATA_SUCCESS:
			return {
				...state,
				userData: {
					...userData,
					folders: userData.buckets,
					canManageBooks: userData.can_manage_books,
					canAccessNextPackages: userData.can_access_next_packages,
				},
				loading: false,
			};

		case sharedTypes.GET_USER_DATA_ERROR:
			return {
				...state,
				loading: false,
			};

		case sharedTypes.CLEAR_USER_DATA:
			return {
				...state,
				userData: initialState.userData,
			};

		case sharedTypes.SEND_EVENT_SUCCESS:
			return {
				...state,
				...data,
			};

		case sharedTypes.SEND_EVENT_ERROR:
			return {
				...state,
			};

		case sharedTypes.SET_TRANSLATIONS_INSTANCE:
			return {
				...state,
				translationsInstance,
			};

		case sharedTypes.SET_BACKGROUND_COLOR:
			return {
				...state,
				backgroundColor,
			};

		case sharedTypes.SET_BACKGROUND_PATTERN:
			return {
				...state,
				backgroundPattern,
			};

		default:
			return state;
	}
}

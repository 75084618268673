import { dailyGoalActionTypes } from './Constants';

const initialState = {
	dailyReport: null,
	reflections: [],
	dailyGoalStickers: null,
	userStickers: null,
	profileStickers: null,
	loadingReport: false,
	loadingReflections: false,
};

export default function dailyGoalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { reflections, dailyReport, dailyGoalStickers, userStickers, profileStickers } =
		payload || {};

	switch (type) {
		case dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_REQUEST:
			return {
				...state,
				loadingReport: true,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_SUCCESS:
			return {
				...state,
				dailyReport,
				loadingReport: false,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_ERROR:
			return {
				...state,
				loadingReport: false,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_REQUEST:
			return {
				...state,
				loadingReflections: true,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_SUCCESS:
			return {
				...state,
				reflections,
				loadingReflections: false,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_ERROR:
			return {
				...state,
				loadingReflections: false,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_STICKERS_SUCCESS:
			return {
				...state,
				dailyGoalStickers,
			};

		case dailyGoalActionTypes.GET_USER_STICKERS_SUCCESS:
		case dailyGoalActionTypes.SEND_DAILY_GOAL_STICKER_SUCCESS:
			return {
				...state,
				userStickers,
			};

		case dailyGoalActionTypes.GET_PROFILE_STICKERS_SUCCESS:
			return {
				...state,
				profileStickers,
			};

		default:
			return state;
	}
}

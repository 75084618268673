/* eslint-disable import/no-cycle */
import activationCodeSagas from './pages/activationCode/Sagas';
import addImageSaga from './components/modals/addImage/Sagas';
import appPushNotificationSagas from './components/appPushNotification/Sagas';
import chooseProfileImageModalSagas from './components/modals/chooseProfileImageModal/Sagas';
import couponReaderSagas from './pages/couponReader/Sagas';
import exerciseSagas from './pages/exercise/Sagas';
import forgotPasswordSagas from './pages/forgotPassword/Sagas';
import languageSelectorSagas from './components/languageSelector/Sagas';
import notificationsSagas from './components/notifications/Sagas';
import packagesSagas from './pages/packages/Sagas';
import parentShop from './pages/publicShop/Sagas';
import payInvoiceModalSagas from './components/modals/payInvoice/Sagas';
import paymentSagas from './pages/successPayment/Sagas';
import playButtonSagas from './components/playButton/Sagas';
import profileSagas from './pages/profile/Sagas';
import resetPasswordSagas from './pages/resetPassword/Sagas';
import searchDetailsSagas from './pages/searchDetails/Sagas';
import searchSagas from './pages/search/Sagas';
import shareVideoModalSagas from './components/modals/shareVideo/Sagas';
import sharedSagas from './shared/Sagas';
import shopSagas from './pages/shop/Sagas';
import signInSagas from './pages/signIn/Sagas';
import signUpSagas from './pages/signUp/Sagas';
import switchPackageModalSagas from './components/modals/switchPackage/Sagas';
import onboardingSaga from './components/onboarding/Sagas';
import reportSaga from './pages/report/Sagas';
import goalSettingsSaga from './pages/goalSettings/Sagas';
import learningPathSaga from './pages/learningPath/Sagas';
import dailyGoalSaga from './pages/dailyGoal/Sagas';
import stickersOnboardingSaga from './pages/stickersOnboarding/Sagas';
import dailyReportSaga from './components/dailyReport/Sagas';
import activitySaga from './pages/activity/Sagas';

const rootSaga = () => {
	return [
		activationCodeSagas,
		addImageSaga,
		appPushNotificationSagas,
		chooseProfileImageModalSagas,
		couponReaderSagas,
		exerciseSagas,
		forgotPasswordSagas,
		languageSelectorSagas,
		notificationsSagas,
		packagesSagas,
		parentShop,
		payInvoiceModalSagas,
		paymentSagas,
		playButtonSagas,
		profileSagas,
		resetPasswordSagas,
		searchDetailsSagas,
		searchSagas,
		shareVideoModalSagas,
		sharedSagas,
		shopSagas,
		signInSagas,
		signUpSagas,
		switchPackageModalSagas,
		onboardingSaga,
		reportSaga,
		goalSettingsSaga,
		learningPathSaga,
		dailyGoalSaga,
		stickersOnboardingSaga,
		dailyReportSaga,
		activitySaga,
	];
};

export default rootSaga;

import keyMirror from 'keymirror';

export const learningPathActionTypes = keyMirror({
	GET_LEARNING_PATH_REQUEST: null,
	GET_LEARNING_PATH_SUCCESS: null,
	GET_LEARNING_PATH_ERROR: null,

	FINISH_LEARNING_PATH_SECTION_REQUEST: null,
	FINISH_LEARNING_PATH_SECTION_SUCCESS: null,
	FINISH_LEARNING_PATH_SECTION_ERROR: null,

	SEND_VIDEO_MODAL_REQUEST: null,
	COMPLETE_ONBOARDING_STEP: null,
	UPDATE_TILE_PROGRESS_STATUS: null,
	SET_LEARNING_PATH_UPDATED: null,
	CLEAR_LEARNING_PATH: null,
	SET_FINISH_TILE_ANIMATION: null,
	SET_SHORTER_LEARNING_PATH_ANIMATION: null,
	SET_SKIP_TILE_ANIMATION: null,
	CHOOSE_SKIP_TILE_MODE: null,
	SET_REWARD_USER_SHEET_ID: null,
	SET_SELECTED_SKIPPED_TILE: null,
	SET_CURRENT_STEPS: null,
	SET_NEW_STEPS: null,
	SET_PREVIEW_MODE: null,

	SEND_COLLECTED_REWARD_REQUEST: null,
	SEND_COLLECTED_REWARD_SUCCESS: null,
	SEND_COLLECTED_REWARD_ERROR: null,
});

export const TILE_STATUSES = {
	TILE_IN_PROGRESS: 'tile_in_progress',
	TILE_LOCKED: 'tile_locked',
	TILE_COMPLETED: 'tile_completed',
};

export const COLORS = {
	LAVENDER: 'lavender',
	TURQUOISE: 'turquoise',
	YELLOW: 'yellow',
	ORANGE: 'orange',
	GREY: 'grey',
};

export const COLORS_MAP = {
	[COLORS.LAVENDER]: '#B199FF',
	[COLORS.TURQUOISE]: '#6EE8D2',
	[COLORS.YELLOW]: '#FFCF0D',
	[COLORS.ORANGE]: '#FF8541',
	[COLORS.GREY]: '#E5E5E5',
};

export const STATUS_TO_CLASS_MAP = {
	[TILE_STATUSES.TILE_IN_PROGRESS]: 'in-progress',
	[TILE_STATUSES.TILE_LOCKED]: 'locked',
	[TILE_STATUSES.TILE_COMPLETED]: 'completed',
};

export const TILES_PER_LINE = 9;

export const DEFAULT_TILE_IMAGE = '🕵️‍♀️';

export const TILE_HEIGHT = 102;

export const LEARNING_PATH_VIMEO_ID = '888732818';

export const SHOW_ONBOARDING_TILE = 'showOnboardingVideoTile';

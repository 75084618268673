import { put, call, takeLatest, all } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { dailyGoalActionTypes } from './Constants';
import * as dailyGoalActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as sharedActions from '../../shared/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_REQUEST,
			handleGetDailyGoalReportRequest,
		),
		yield takeLatest(
			dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_REQUEST,
			handleGetDailyGoalReflectionsRequest,
		),
		yield takeLatest(
			dailyGoalActionTypes.SEND_DAILY_GOAL_REFLECTION_REQUEST,
			handleSendDailyGoalReflectionRequest,
		),
		yield takeLatest(
			dailyGoalActionTypes.GET_DAILY_GOAL_STICKERS_REQUEST,
			handleGetDailyGoalStickersRequest,
		),
		yield takeLatest(
			dailyGoalActionTypes.SEND_DAILY_GOAL_STICKER_REQUEST,
			handleSendDailyGoalStickerRequest,
		),
		yield takeLatest(
			dailyGoalActionTypes.GET_USER_STICKERS_REQUEST,
			handleGetUserStickersRequest,
		),
		yield takeLatest(
			dailyGoalActionTypes.GET_PROFILE_STICKERS_REQUEST,
			handleGetProfileStickersRequest,
		),
		yield takeLatest(dailyGoalActionTypes.COLLECT_REWARD_REQUEST, handleCollectRewardRequest),
		yield takeLatest(dailyGoalActionTypes.UPDATE_REWARD_REQUEST, handleUpdateRewardRequest),
	]);
}

export function* handleGetDailyGoalReportRequest() {
	try {
		const { data } = yield call(processRequest, `users/daily_report`, 'GET');

		yield put(dailyGoalActions.getDailyGoalReportSuccess(data));
	} catch (e) {
		yield put(dailyGoalActions.getDailyGoalReportError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetDailyGoalReflectionsRequest() {
	try {
		const { data } = yield call(processRequest, 'user_goal_reflections/valuations');

		yield put(dailyGoalActions.getDailyGoalReflectionsSuccess(data));
	} catch (e) {
		yield put(dailyGoalActions.getDailyGoalReflectionsError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleSendDailyGoalReflectionRequest({ payload }) {
	try {
		const { value } = payload || {};
		const body = { user_goal_reflection: { valuation: value } };

		yield call(processRequest, 'user_goal_reflections', 'POST', body);

		yield put(dailyGoalActions.sendDailyGoalReflectionSuccess());
	} catch (e) {
		yield put(dailyGoalActions.sendDailyGoalReflectionError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetDailyGoalStickersRequest() {
	try {
		const { data } = yield call(processRequest, `user_stickers/daygoal_list`, 'GET');

		yield put(dailyGoalActions.getDailyGoalStickersSuccess(data));
	} catch (e) {
		yield put(dailyGoalActions.getDailyGoalStickersError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleSendDailyGoalStickerRequest({ payload }) {
	try {
		const { sticker } = payload || {};

		const { data } = yield call(processRequest, 'user_stickers', 'POST', sticker);

		yield put(dailyGoalActions.sendDailyGoalStickerSuccess(data));
	} catch (e) {
		yield put(dailyGoalActions.sendDailyGoalStickerError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetUserStickersRequest() {
	try {
		const { data } = yield call(processRequest, `user_stickers`, 'GET');

		yield put(dailyGoalActions.getUserStickersSuccess(data));
	} catch (e) {
		yield put(dailyGoalActions.getUserStickersError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetProfileStickersRequest() {
	try {
		const { data } = yield call(processRequest, 'user_stickers/list', 'GET');

		yield put(dailyGoalActions.getProfileStickersSuccess(data));
	} catch (e) {
		yield put(dailyGoalActions.getProfileStickersError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleCollectRewardRequest({ payload }) {
	try {
		const { id, body } = payload || {};

		const { data } = yield call(
			processRequest,
			`user_stickers/${id}/collect_reward`,
			'POST',
			body,
		);

		const { data: userData } = yield call(processRequest, 'get_user_data');

		yield put(sharedActions.getUserDataSuccess(userData));

		yield put(dailyGoalActions.collectRewardSuccess(data));
	} catch (e) {
		yield put(dailyGoalActions.collectRewardError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleUpdateRewardRequest({ payload }) {
	try {
		const { id, body, callback } = payload || {};

		yield call(processRequest, `user_stickers/${id}/update_reward`, 'PUT', body);

		const { data: userData } = yield call(processRequest, 'get_user_data');

		yield put(sharedActions.getUserDataSuccess(userData));

		callback && callback();

		yield put(dailyGoalActions.updateRewardSuccess());
	} catch (e) {
		yield put(dailyGoalActions.updateRewardError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

import { topicsActionTypes } from './Constants';

export function getTopicsRequest(appliedFilters) {
	return {
		type: topicsActionTypes.GET_ADMIN_TOPICS_REQUEST,
		payload: { appliedFilters },
	};
}

export function getTopicsSuccess({ available_filters, subcategories }) {
	return {
		type: topicsActionTypes.GET_ADMIN_TOPICS_SUCCESS,
		payload: { available_filters, subcategories },
	};
}

export function getTopicsError() {
	return {
		type: topicsActionTypes.GET_ADMIN_TOPICS_ERROR,
	};
}

export function setTopicOpenState({ topicId, open }) {
	return {
		type: topicsActionTypes.SET_ADMIN_TOPIC_OPEN_STATE,
		payload: { topicId, open },
	};
}

export function setAllTopicsOpenState(open) {
	return {
		type: topicsActionTypes.SET_ADMIN_ALL_TOPICS_OPEN_STATE,
		payload: { open },
	};
}

export function changeTopicPositionRequest({ id, position, callback }) {
	return {
		type: topicsActionTypes.CHANGE_ADMIN_TOPIC_POSITION_REQUEST,
		payload: { id, position, callback },
	};
}

export function changeTopicPositionSuccess() {
	return {
		type: topicsActionTypes.CHANGE_ADMIN_TOPIC_POSITION_SUCCESS,
	};
}

export function changeTopicPositionError() {
	return {
		type: topicsActionTypes.CHANGE_ADMIN_TOPIC_POSITION_ERROR,
	};
}

export function deleteTopicRequest({ id, callback }) {
	return {
		type: topicsActionTypes.DELETE_ADMIN_TOPIC_REQUEST,
		payload: { id, callback },
	};
}

export function deleteTopicSuccess() {
	return {
		type: topicsActionTypes.DELETE_ADMIN_TOPIC_SUCCESS,
	};
}

export function deleteTopicError() {
	return {
		type: topicsActionTypes.DELETE_ADMIN_TOPIC_ERROR,
	};
}

export function getTopicPlaylistsRequest({ topicId }) {
	return {
		type: topicsActionTypes.GET_TOPIC_PLAYLISTS_REQUEST,
		payload: { topicId },
	};
}

export function getTopicPlaylistsSuccess({ topicId, playlists }) {
	return {
		type: topicsActionTypes.GET_TOPIC_PLAYLISTS_SUCCESS,
		payload: { topicId, playlists },
	};
}

export function getTopicPlaylistsError() {
	return {
		type: topicsActionTypes.GET_TOPIC_PLAYLISTS_ERROR,
	};
}

export function deletePlaylistRequest({ playlistId, callback }) {
	return {
		type: topicsActionTypes.DELETE_PLAYLIST_REQUEST,
		payload: { playlistId, callback },
	};
}

export function deletePlaylistSuccess() {
	return {
		type: topicsActionTypes.DELETE_PLAYLIST_SUCCESS,
	};
}

export function deletePlaylistError() {
	return {
		type: topicsActionTypes.DELETE_PLAYLIST_ERROR,
	};
}

export function duplicatePlaylistRequest({ playlistId, callback }) {
	return {
		type: topicsActionTypes.DUPLICATE_PLAYLIST_REQUEST,
		payload: { playlistId, callback },
	};
}

export function duplicatePlaylistSuccess() {
	return {
		type: topicsActionTypes.DUPLICATE_PLAYLIST_SUCCESS,
	};
}

export function duplicatePlaylistError() {
	return {
		type: topicsActionTypes.DUPLICATE_PLAYLIST_ERROR,
	};
}

export function cleanTopicsState() {
	return {
		type: topicsActionTypes.CLEAN_ADMIN_TOPICS_STATE,
	};
}

export function setAppliedFilters(appliedFilters) {
	return {
		type: topicsActionTypes.SET_ADMIN_APPLIED_FILTERS,
		payload: { appliedFilters },
	};
}

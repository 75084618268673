import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { exerciseActionTypes } from './Constants';
import { getUserId } from './Selectors';
import { getPlayListSections } from '../report/Selectors';
import * as sharedActions from '../../shared/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as exerciseActions from './Actions';

export default function* () {
	yield all([
		yield takeLatest(
			exerciseActionTypes.GET_QUESTION_EXPLANATION,
			handleQuestionExplanationRequest,
		),
		yield takeLatest(exerciseActionTypes.GET_EXERCISE_REQUEST, handleGetExerciseRequest),
		yield takeLatest(
			exerciseActionTypes.GET_EXERCISE_REPORT_REQUEST,
			handleGetExerciseReportRequest,
		),
		yield takeLatest(exerciseActionTypes.SEND_QUESTION_ANSWER_REQUEST, handleSendAnswerRequest),
		yield takeLatest(
			exerciseActionTypes.SEND_TOPIC_INSTRUCTION_FEEDBACK,
			handleSendTopicInstructionFeedback,
		),
		yield takeLatest(
			exerciseActionTypes.SEND_QUESTION_INSTRUCTION_FEEDBACK,
			handleSendQuestionInstructionFeedback,
		),
		yield takeLatest(exerciseActionTypes.STORE_QUESTION_SET_TIME, handleStoreTime),
		yield takeLatest(exerciseActionTypes.STORE_QUESTION_SET_TIME_BETWEEN, handleStoreTime),
		yield takeLatest(
			exerciseActionTypes.SAVE_AUDIO_PREFERENCES_REQUEST,
			handleSaveAudioPreferencesRequest,
		),
	]);
}

export function* handleQuestionExplanationRequest(action) {
	try {
		const { questionId, exerciseId } = action.payload;

		const { data } = yield call(processRequest, `questions/${questionId}/explanation`, 'GET', {
			exercise_id: exerciseId,
			object_id: questionId,
		});

		yield put(
			exerciseActions.getQuestionExplanationSuccess({
				explanation: data.explanation,
			}),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetExerciseRequest({ payload = {} }) {
	try {
		const { id } = payload;
		const { data } = yield call(processRequest, `user_learning_path_sections/${id}`, 'GET');

		yield put(exerciseActions.getExerciseSuccess(data.user_learning_path_section));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(exerciseActions.getExerciseError());
	}
}

export function* handleGetExerciseReportRequest({ payload = {} }) {
	try {
		const { id } = payload;
		const playListSections = yield select(getPlayListSections);

		if (playListSections[id]) {
			yield put(exerciseActions.getExerciseReportSuccess(playListSections[id]));
			return;
		}

		const { data } = yield call(processRequest, `user_learning_path_sections/${id}`, 'GET', {
			preview: true,
		});

		yield put(exerciseActions.getExerciseReportSuccess(data.user_learning_path_section));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(exerciseActions.getExerciseReportError());
	}
}

export function* handleSendAnswerRequest({ payload }) {
	try {
		const { data, callback } = payload || {};

		yield call(processRequest, `playlist_section_answers`, 'POST', data);
		yield put(exerciseActions.sendAnswerSuccess());

		callback && callback();
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(exerciseActions.sendAnswerError());
	}
}

export function* handleSendTopicInstructionFeedback({ payload }) {
	const { isUseful, instructionId, questionId } = payload || {};
	try {
		const instruction_feedback = {
			instruction_id: instructionId,
			question_id: questionId,
			feedback: isUseful,
		};
		yield call(processRequest, 'instruction_feedbacks', 'POST', instruction_feedback);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleSendQuestionInstructionFeedback({ payload }) {
	const { feedback, questionId } = payload || {};
	try {
		const instruction_feedback = {
			question_id: questionId,
			feedback: feedback,
		};
		yield call(processRequest, 'instruction_feedbacks', 'POST', instruction_feedback);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleStoreTime({ payload }) {
	try {
		yield call(processRequest, 'user_questionset_questions/time_update', 'POST', payload);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleSaveAudioPreferencesRequest({ payload }) {
	try {
		const userId = yield select(getUserId);
		const { audioOn, audioOnboarded } = payload;

		const data = { audio_on: audioOn };
		if (audioOnboarded !== undefined) {
			data.question_instruction_onboarded = audioOnboarded;
		}

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: data,
		});

		yield put(sharedActions.getUserDataRequest());

		yield put(exerciseActions.saveAudioPreferencesSuccess());
	} catch (e) {
		yield put(exerciseActions.saveAudioPreferencesError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

import { cloneDeep } from 'lodash';
import { DURATION_COLOR_LABELS, goalSettingsActionTypes, STATUSES } from './Constants';
import {
	setSelectedDuration,
	getGoalSettingsSteps,
	getTotalProgress,
	setSelectedSubject,
	setSelectedCategory,
	setSelectedSubcategory,
	setExtraProperties,
} from './Helpers';
const initialState = {
	loadingGoalSettings: false,
	loadingDurations: false,
	loadingSave: false,
	steps: [],
	subjects: [],
	durations: [],
	initialSubjects: [],
	initialDurations: [],
	totalProgress: 0,
	currentProgress: 0,
	existingGoal: false,
	forceFirstStep: false,
	discardChangesModal: {
		open: false,
		submitCallback: null,
	},
};

export default function goalSettingsReducer(state = initialState, action) {
	const { type, payload } = action;
	const {
		data,
		subject,
		subjectId,
		categoryId,
		subcategoryId,
		selected,
		progress,
		duration,
		flag,
	} = payload || {};

	switch (type) {
		case goalSettingsActionTypes.GET_GOAL_SETTINGS_REQUEST:
			return {
				...state,
				steps: getGoalSettingsSteps(state.subjects),
				loadingGoalSettings: true,
			};

		case goalSettingsActionTypes.GET_GOAL_DURATIONS_REQUEST:
			return {
				...state,
				loadingDurations: true,
			};

		case goalSettingsActionTypes.GET_GOAL_SETTINGS_SUCCESS:
			const steps = getGoalSettingsSteps(data);
			const totalProgress = getTotalProgress(data);
			const existingGoal = data.some((subject) => subject.selected);
			const currentProgress = existingGoal && !state.forceFirstStep ? steps.length - 2 : 0;
			const customSubjects = setExtraProperties(data);

			return {
				...state,
				steps,
				initialSubjects: cloneDeep(customSubjects),
				subjects: customSubjects,
				totalProgress,
				existingGoal,
				currentProgress,
				loadingGoalSettings: false,
			};

		case goalSettingsActionTypes.GET_GOAL_DURATIONS_SUCCESS:
			const durations = data.map((duration, index) => {
				duration.label_color = DURATION_COLOR_LABELS[index];
				return duration;
			});
			return {
				...state,
				initialDurations: cloneDeep(durations),
				durations,
				loadingDurations: false,
			};

		case goalSettingsActionTypes.GET_GOAL_SETTINGS_ERROR:
			return {
				...state,
				loadingGoalSettings: false,
			};

		case goalSettingsActionTypes.GET_GOAL_DURATIONS_ERROR:
			return {
				...state,
				loadingDurations: false,
			};

		case goalSettingsActionTypes.SET_SELECTED_SUBJECT:
			const subjects = setSelectedSubject(subject, selected, state.subjects);
			return {
				...state,
				subjects,
				steps: getGoalSettingsSteps(subjects),
				totalProgress: getTotalProgress(subjects),
			};

		case goalSettingsActionTypes.SET_SELECTED_CATEGORY:
			return {
				...state,
				subjects: setSelectedCategory(subjectId, categoryId, selected, state.subjects),
			};

		case goalSettingsActionTypes.SET_SELECTED_SUBCATEGORY:
			return {
				...state,
				subjects: setSelectedSubcategory(
					subjectId,
					categoryId,
					subcategoryId,
					selected,
					state.subjects,
				),
			};

		case goalSettingsActionTypes.SET_SELECTED_DURATION:
			return {
				...state,
				durations: setSelectedDuration(duration, state.durations),
			};

		case goalSettingsActionTypes.SET_CURRENT_PROGRESS:
			return {
				...state,
				currentProgress: progress,
			};

		case goalSettingsActionTypes.SAVE_GOAL_SETTING_REQUEST:
			return {
				...state,
				loadingSave: true,
			};

		case goalSettingsActionTypes.SAVE_GOAL_SETTING_SUCCESS:
		case goalSettingsActionTypes.SAVE_GOAL_SETTING_ERROR:
			return {
				...state,
				loadingSave: false,
			};

		case goalSettingsActionTypes.SET_FORCE_FIRST_STEP:
			return {
				...state,
				forceFirstStep: flag,
			};

		case goalSettingsActionTypes.TOGGLE_DISCARD_CHANGES_MODAL:
			return {
				...state,
				discardChangesModal: data,
			};

		default:
			return state;
	}
}

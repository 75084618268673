import { learningPathActionTypes } from './Constants';

export function getLearningPathRequest() {
	return {
		type: learningPathActionTypes.GET_LEARNING_PATH_REQUEST,
	};
}

export function getLearningPathSuccess(data) {
	return {
		type: learningPathActionTypes.GET_LEARNING_PATH_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getLearningPathError() {
	return {
		type: learningPathActionTypes.GET_LEARNING_PATH_ERROR,
	};
}

export function finishLearningPathSectionRequest(sectionId, callback) {
	return {
		type: learningPathActionTypes.FINISH_LEARNING_PATH_SECTION_REQUEST,
		payload: {
			sectionId,
			callback,
		},
	};
}

export function finishLearningPathSectionSuccess() {
	return {
		type: learningPathActionTypes.FINISH_LEARNING_PATH_SECTION_SUCCESS,
	};
}

export function finishLearningPathSectionError() {
	return {
		type: learningPathActionTypes.FINISH_LEARNING_PATH_SECTION_ERROR,
	};
}

export function sendVideoModalRequest() {
	return {
		type: learningPathActionTypes.SEND_VIDEO_MODAL_REQUEST,
	};
}

export function completeOnboardingStep() {
	return {
		type: learningPathActionTypes.COMPLETE_ONBOARDING_STEP,
	};
}

export function updateTileProgressStatus(stepId, isCorrect) {
	return {
		type: learningPathActionTypes.UPDATE_TILE_PROGRESS_STATUS,
		payload: {
			stepId,
			isCorrect,
		},
	};
}

export function setLearningPathUpdated(isUpdated) {
	return {
		type: learningPathActionTypes.SET_LEARNING_PATH_UPDATED,
		payload: {
			isUpdated,
		},
	};
}

export function setFinishTileAnimation(finishTileAnimation) {
	return {
		type: learningPathActionTypes.SET_FINISH_TILE_ANIMATION,
		payload: {
			finishTileAnimation,
		},
	};
}

export function setChooseSkipTileMode(chooseSkipTileMode) {
	return {
		type: learningPathActionTypes.CHOOSE_SKIP_TILE_MODE,
		payload: {
			chooseSkipTileMode,
		},
	};
}

export function setShorterLearningPathAnimation(shorterLearningPathAnimation) {
	return {
		type: learningPathActionTypes.SET_SHORTER_LEARNING_PATH_ANIMATION,
		payload: {
			shorterLearningPathAnimation,
		},
	};
}

export function setSkipTileAnimation(skipTileAnimation) {
	return {
		type: learningPathActionTypes.SET_SKIP_TILE_ANIMATION,
		payload: {
			skipTileAnimation,
		},
	};
}

export function setRewardUserSheetId(rewardUserSheetId) {
	return {
		type: learningPathActionTypes.SET_REWARD_USER_SHEET_ID,
		payload: {
			rewardUserSheetId,
		},
	};
}

export function setSelectedSkippedTile(selectedSkippedTile) {
	return {
		type: learningPathActionTypes.SET_SELECTED_SKIPPED_TILE,
		payload: {
			selectedSkippedTile,
		},
	};
}

export function setCurrentSteps(steps) {
	return {
		type: learningPathActionTypes.SET_CURRENT_STEPS,
		payload: {
			steps,
		},
	};
}

export function setNewSteps(newSteps) {
	return {
		type: learningPathActionTypes.SET_NEW_STEPS,
		payload: {
			newSteps,
		},
	};
}

export function sendCollectedRewardRequest(id, body, rewardType) {
	return {
		type: learningPathActionTypes.SEND_COLLECTED_REWARD_REQUEST,
		payload: {
			id,
			body,
			rewardType,
		},
	};
}

export function setPreviewMode(previewMode) {
	return {
		type: learningPathActionTypes.SET_PREVIEW_MODE,
		payload: {
			previewMode,
		},
	};
}

export function clearLearningPath() {
	return {
		type: learningPathActionTypes.CLEAR_LEARNING_PATH,
	};
}

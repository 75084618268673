import configData from '../config.json';
import i18n from 'i18next';

export const BASE_API_URL = configData.api_url;
export const WSS_URL = configData.wss_url;
export const BASE_URL = configData.resources_url;
export const FRONTEND_URL = configData.frontend_url;
export const ADMIN_FRONTEND_URL = configData.admin_frontend_url;
export const CHARGEBEE_PUB_KEY = configData.chargebee_pub_key;
export const CHARGEBEE_SITE = configData.chargebee_site;
export const ADYEN_ORIGIN_KEY = configData.adyen_origin_key;
export const ADYEN_ENV = configData.adyen_env;
export const SERVICE_EMAIL = configData.service_email;
export const FACEBOOK_APP_ID = configData.facebook_app_id;
export const APPLE_CLIENT_ID = configData.apple_client_id;
export const GTM_CONTAINER_ID = configData.gtm_container_id;
export const GA4_TAG_ID = configData.ga4_tag_id;
export const RECAPTCHA_KEY = configData.recaptcha_key;
export const APP_ENVIRONMENT = configData.environment;
export const TYPO_CHECKER_TOKEN = configData.typo_checker_token;
export const MARKETING_URL = configData.marketing_url;
export const TYPO_CHECKER_API_URL = configData.typo_checker_api_url;
export const PRIVACY_POLICY_URL = configData.privacy_policy_url;
export const TYPE = configData.type;
export const AVAILABLE_LANGUAGES = configData.available_languages;
export const BRAND_NAME = configData.brand_name;
export const AGREEMENT_URL = configData.agreement_url;
export const PRIVACY_POLICY_TEACHER_URL = configData.privacy_policy_teacher_url;
export const TERMS_URL = configData.terms_url;
export const BASENAME = configData.basename;
export const EXIT_SURVEY_URL = configData.exit_survey_url;
export const BUGSNAG_API_KEY = configData.bugsnag_api_key;
export const REACT_APP_BUILD_NUMBER = configData.react_app_build_number;
export const ORDER_CONFIRMATION_SURVEY_CODE = configData.order_confirmation_survey_code;
export const SENTRY_URL = configData.sentry_url;

export const PLATFORMS = {
	IOS: 'ios',
	ANDROID: 'android',
};

export const IS_ADMIN = process.env.REACT_APP_BUILD_TARGET === 'admin';
export const IS_MOBILE_DEVICE =
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const IS_MOBILE_APP = !!window.cordova;
export const IS_IOS_APP = window.cordova && window.cordova.platformId === PLATFORMS.IOS;
export const IS_ANDROID_APP = window.cordova && window.cordova.platformId === PLATFORMS.ANDROID;
export const IS_STAGING_ENVIRONMENT = APP_ENVIRONMENT === 'staging';
export const IS_INTERNATIONAL_TYPE = TYPE === 'internationale';

export const DEVICE_TYPE = {
	type: undefined,
	touch: undefined,
};

export const COOKIES = {
	accessToken: IS_STAGING_ENVIRONMENT ? 'tokenStaging' : 'token',
	temporaryToken: 'temporaryToken',
	renewTime: 'renewTime',
	loggedIn: 'loggedIn',
	coupon: 'coupon',
	deviceToken: 'deviceToken',
	activationCode: 'activationCode',
	lastPageSlug: 'lastPageSlug',
	lastGclidTag: 'lastGclidTag',
};

export const IS_MOBILE_VIEW = window.innerWidth < 768;
export const IS_TABLET_VIEW = window.innerWidth < 1024;
export const IS_DESKTOP_VIEW = window.innerWidth >= 1024;
export const MIN_ANSWERS_VARIANTS_FOR_MULTIPLE_TYPE = 4;

export const MIN_DAY = 1;
export const MAX_DAY = 31;
export const MIN_YEAR = 1900;
export const MAX_YEAR = new Date().getFullYear();
export const MIN_PASSWORD_LENGTH = 5;

export const AUTO_HIDE_TIMEOUT = 1000;

export const SEARCH_PATH = '/search';
export const PACKAGES_PATH = '/packages';

export const UTM_TAGS_TTL_DAYS = 1;

export const IMAGES_EXTENSIONS = [
	'image/png',
	'image/jpeg',
	'image/jpg',
	'image/gif',
	'image/svg+xml',
];

export const FILES_EXTENSIONS = [
	'application/doc',
	'application/docx',
	'application/pdf',
	'application/txt',
	'application/xlsx',
	'application/xls',
	'application/csv',
];

export const MAX_IMAGE_SIZE = 5000000;

export const MAX_FILE_SIZE = 5000000;

export const MAX_ATTACHMENTS = 10;

export const LIST_ITEM_STATUS = {
	DRAFT: 'draft',
	CONCEPT: 'concept',
};

export const exerciseTypeCodesNext = {
	OPEN_ENDED: 'Question::OpenEnded',
	DICTATE: 'Question::Dictation',
	IN_YOUR_MIND: 'Question::InYourMind',
	MULTIPLE_CHOICE: 'Question::MultipleChoiceSingleAnswer',
	THE_BLANKS: 'Question::FillInTheBlanks',
	OPEN_MULTIPLE: 'Question::OpenQuestionMultipleAnswers',
	DICTATE_MULTIPLE: 'Question::DictationMultipleChoice',
	MULTIPLE_CHOICE_MULTIPLE_ANSWERS: 'Question::MultipleChoiceMultipleAnswers',
};

export const EXERCISE_VIEW_MODES = {
	REGULAR: 'regular',
	PREVIEW: 'preview',
	REPORT: 'report',
};

export const SOURCE_TYPES = {
	TOPIC: 'topic',
	HOME: 'home',
	TEST_QUESTION: 'test_question',
	TOPIC_QUESTION: 'topic_question',
	TOPIC_DETAIL: 'topic_detail',
	RESULT_DETAIL: 'result_detail',
	DIAGNOSTIC_TEST: 'diagnostic_test',
	DIAGNOSTIC_TEST_RESULT: 'diagnostic_test_result',
	TOPIC_EXERCISE: 'topic_exercise',
	TOPIC_RESULT: 'topic_result',
	QUIZ_TEST: 'quiz_test',
	QUIZ_RESULT: 'quiz_result',
	QUIZ_DETAILS: 'quiz_details',
	EXERCISE_DETAIL: 'exercise_detail',
	MY_PACKAGES: 'my_packages',
	PACKAGES: 'packages',
	LIST_DETAIL: 'list_detail',
	ANSWER_PANEL: 'answer_panel',
	TOPIC_PANEL: 'topic_panel',
	UPGRADE_BUTTON: 'upgrade_button',
	MENU: 'menu',
	RECENT_LIST: 'recent',
	QUESTION_DETAIL: 'question_detail',
};

export const UPGRADE_MODALS_IDENTIFIER = {
	LOCKED_CONTENT: 'locked_content',
	MIXED_LANGUAGES: 'mixed_languages',
	START_DIAGNOSTIC_TEST: 'start_diagnostic_test',
	START_NEW_DIAGNOSTIC_TEST: 'start_new_diagnostic_test',
	START_TOPIC_EXERCISE: 'start_topic_exercise',
	EXPLANATION_VIDEO: 'explanation_video',
	IOS_APP: 'ios_app',
	EXERCISE_COMPLETED: 'exercise_completed',
	FIRST_RETURN: 'first_return',
	RESTRICTED_DICTATION: 'restricted_dictation',
	PACKAGE_PAGE_VIEWED: 'package_page_viewed',
	UPGRADE_MESSAGE: 'upgrade_message',
};

export const APPLE_STORE_LINK = 'https://apps.apple.com/nl/app/wrts-woordjes-leren/id1191740855';
export const GOOGLE_PLAY_STORE_LINK =
	'https://play.google.com/store/apps/details?id=nl.wrts.mobiel';

export const PUNCTUATION_CHARACTERS = [',', '-', '.', '!', '?', '_', '+', ':'];

export const ACCESS_TOKEN_EXPIRATION_DAYS = 365;

export const SECONDS_IN_HOURS = 3600;
export const HOURS_IN_MONTH = 730.5;
export const MAIN_YELLOW_COLOR = '#FFD600';
export const MAIN_PURPLE_COLOR = '#652EA3';
export const ALL_LANGUAGES_CODES = [
	{ code: 'ab', name: 'Abkhazian' },
	{ code: 'aa', name: 'Afar' },
	{ code: 'af', name: 'Afrikaans' },
	{ code: 'ak', name: 'Akan' },
	{ code: 'sq', name: 'Albanian' },
	{ code: 'am', name: 'Amharic' },
	{ code: 'ar', name: 'Arabic' },
	{ code: 'an', name: 'Aragonese' },
	{ code: 'hy', name: 'Armenian' },
	{ code: 'as', name: 'Assamese' },
	{ code: 'av', name: 'Avaric' },
	{ code: 'ae', name: 'Avestan' },
	{ code: 'ay', name: 'Aymara' },
	{ code: 'az', name: 'Azerbaijani' },
	{ code: 'bm', name: 'Bambara' },
	{ code: 'ba', name: 'Bashkir' },
	{ code: 'eu', name: 'Basque' },
	{ code: 'be', name: 'Belarusian' },
	{ code: 'bn', name: 'Bengali' },
	{ code: 'bh', name: 'Bihari languages' },
	{ code: 'bi', name: 'Bislama' },
	{ code: 'bs', name: 'Bosnian' },
	{ code: 'br', name: 'Breton' },
	{ code: 'bg', name: 'Bulgarian' },
	{ code: 'my', name: 'Burmese' },
	{ code: 'ca', name: 'Catalan, Valencian' },
	{ code: 'km', name: 'Central Khmer' },
	{ code: 'ch', name: 'Chamorro' },
	{ code: 'ce', name: 'Chechen' },
	{ code: 'ny', name: 'Chichewa, Chewa, Nyanja' },
	{ code: 'zh', name: 'Chinese' },
	{ code: 'cu', name: 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
	{ code: 'cv', name: 'Chuvash' },
	{ code: 'kw', name: 'Cornish' },
	{ code: 'co', name: 'Corsican' },
	{ code: 'cr', name: 'Cree' },
	{ code: 'hr', name: 'Croatian' },
	{ code: 'cs', name: 'Czech' },
	{ code: 'da', name: 'Danish' },
	{ code: 'dv', name: 'Divehi, Dhivehi, Maldivian' },
	{ code: 'nl', name: 'Dutch, Flemish' },
	{ code: 'dz', name: 'Dzongkha' },
	{ code: 'en', name: 'English' },
	{ code: 'eo', name: 'Esperanto' },
	{ code: 'et', name: 'Estonian' },
	{ code: 'ee', name: 'Ewe' },
	{ code: 'fo', name: 'Faroese' },
	{ code: 'fj', name: 'Fijian' },
	{ code: 'fi', name: 'Finnish' },
	{ code: 'fr', name: 'French' },
	{ code: 'ff', name: 'Fulah' },
	{ code: 'gd', name: 'Gaelic, Scottish Gaelic' },
	{ code: 'gl', name: 'Galician' },
	{ code: 'lg', name: 'Ganda' },
	{ code: 'ka', name: 'Georgian' },
	{ code: 'de', name: 'German' },
	{ code: 'ki', name: 'Gikuyu, Kikuyu' },
	{ code: 'el', name: 'Greek (Modern)' },
	{ code: 'kl', name: 'Greenlandic, Kalaallisut' },
	{ code: 'gn', name: 'Guarani' },
	{ code: 'gu', name: 'Gujarati' },
	{ code: 'ht', name: 'Haitian, Haitian Creole' },
	{ code: 'ha', name: 'Hausa' },
	{ code: 'he', name: 'Hebrew' },
	{ code: 'hz', name: 'Herero' },
	{ code: 'hi', name: 'Hindi' },
	{ code: 'ho', name: 'Hiri Motu' },
	{ code: 'hu', name: 'Hungarian' },
	{ code: 'is', name: 'Icelandic' },
	{ code: 'io', name: 'Ido' },
	{ code: 'ig', name: 'Igbo' },
	{ code: 'id', name: 'Indonesian' },
	{ code: 'ia', name: 'Interlingua (International Auxiliary Language Association)' },
	{ code: 'ie', name: 'Interlingue' },
	{ code: 'iu', name: 'Inuktitut' },
	{ code: 'ik', name: 'Inupiaq' },
	{ code: 'ga', name: 'Irish' },
	{ code: 'it', name: 'Italian' },
	{ code: 'ja', name: 'Japanese' },
	{ code: 'jv', name: 'Javanese' },
	{ code: 'kn', name: 'Kannada' },
	{ code: 'kr', name: 'Kanuri' },
	{ code: 'ks', name: 'Kashmiri' },
	{ code: 'kk', name: 'Kazakh' },
	{ code: 'rw', name: 'Kinyarwanda' },
	{ code: 'kv', name: 'Komi' },
	{ code: 'kg', name: 'Kongo' },
	{ code: 'ko', name: 'Korean' },
	{ code: 'kj', name: 'Kwanyama, Kuanyama' },
	{ code: 'ku', name: 'Kurdish' },
	{ code: 'ky', name: 'Kyrgyz' },
	{ code: 'lo', name: 'Lao' },
	{ code: 'la', name: 'Latin' },
	{ code: 'lv', name: 'Latvian' },
	{ code: 'lb', name: 'Letzeburgesch, Luxembourgish' },
	{ code: 'li', name: 'Limburgish, Limburgan, Limburger' },
	{ code: 'ln', name: 'Lingala' },
	{ code: 'lt', name: 'Lithuanian' },
	{ code: 'lu', name: 'Luba-Katanga' },
	{ code: 'mk', name: 'Macedonian' },
	{ code: 'mg', name: 'Malagasy' },
	{ code: 'ms', name: 'Malay' },
	{ code: 'ml', name: 'Malayalam' },
	{ code: 'mt', name: 'Maltese' },
	{ code: 'gv', name: 'Manx' },
	{ code: 'mi', name: 'Maori' },
	{ code: 'mr', name: 'Marathi' },
	{ code: 'mh', name: 'Marshallese' },
	{ code: 'ro', name: 'Moldovan, Moldavian, Romanian' },
	{ code: 'mn', name: 'Mongolian' },
	{ code: 'na', name: 'Nauru' },
	{ code: 'nv', name: 'Navajo, Navaho' },
	{ code: 'nd', name: 'Northern Ndebele' },
	{ code: 'ng', name: 'Ndonga' },
	{ code: 'ne', name: 'Nepali' },
	{ code: 'se', name: 'Northern Sami' },
	{ code: 'no', name: 'Norwegian' },
	{ code: 'nb', name: 'Norwegian Bokmål' },
	{ code: 'nn', name: 'Norwegian Nynorsk' },
	{ code: 'ii', name: 'Nuosu, Sichuan Yi' },
	{ code: 'oc', name: 'Occitan (post 1500)' },
	{ code: 'oj', name: 'Ojibwa' },
	{ code: 'or', name: 'Oriya' },
	{ code: 'om', name: 'Oromo' },
	{ code: 'os', name: 'Ossetian, Ossetic' },
	{ code: 'pi', name: 'Pali' },
	{ code: 'pa', name: 'Panjabi, Punjabi' },
	{ code: 'ps', name: 'Pashto, Pushto' },
	{ code: 'fa', name: 'Persian' },
	{ code: 'pl', name: 'Polish' },
	{ code: 'pt', name: 'Portuguese' },
	{ code: 'qu', name: 'Quechua' },
	{ code: 'rm', name: 'Romansh' },
	{ code: 'rn', name: 'Rundi' },
	{ code: 'ru', name: 'Russian' },
	{ code: 'sm', name: 'Samoan' },
	{ code: 'sg', name: 'Sango' },
	{ code: 'sa', name: 'Sanskrit' },
	{ code: 'sc', name: 'Sardinian' },
	{ code: 'sr', name: 'Serbian' },
	{ code: 'sn', name: 'Shona' },
	{ code: 'sd', name: 'Sindhi' },
	{ code: 'si', name: 'Sinhala, Sinhalese' },
	{ code: 'sk', name: 'Slovak' },
	{ code: 'sl', name: 'Slovenian' },
	{ code: 'so', name: 'Somali' },
	{ code: 'st', name: 'Sotho, Southern' },
	{ code: 'nr', name: 'South Ndebele' },
	{ code: 'es', name: 'Spanish, Castilian' },
	{ code: 'su', name: 'Sundanese' },
	{ code: 'sw', name: 'Swahili' },
	{ code: 'ss', name: 'Swati' },
	{ code: 'sv', name: 'Swedish' },
	{ code: 'tl', name: 'Tagalog' },
	{ code: 'ty', name: 'Tahitian' },
	{ code: 'tg', name: 'Tajik' },
	{ code: 'ta', name: 'Tamil' },
	{ code: 'tt', name: 'Tatar' },
	{ code: 'te', name: 'Telugu' },
	{ code: 'th', name: 'Thai' },
	{ code: 'bo', name: 'Tibetan' },
	{ code: 'ti', name: 'Tigrinya' },
	{ code: 'to', name: 'Tonga (Tonga Islands)' },
	{ code: 'ts', name: 'Tsonga' },
	{ code: 'tn', name: 'Tswana' },
	{ code: 'tr', name: 'Turkish' },
	{ code: 'tk', name: 'Turkmen' },
	{ code: 'tw', name: 'Twi' },
	{ code: 'ug', name: 'Uighur, Uyghur' },
	{ code: 'uk', name: 'Ukrainian' },
	{ code: 'ur', name: 'Urdu' },
	{ code: 'uz', name: 'Uzbek' },
	{ code: 've', name: 'Venda' },
	{ code: 'vi', name: 'Vietnamese' },
	{ code: 'vo', name: 'Volap_k' },
	{ code: 'wa', name: 'Walloon' },
	{ code: 'cy', name: 'Welsh' },
	{ code: 'fy', name: 'Western Frisian' },
	{ code: 'wo', name: 'Wolof' },
	{ code: 'xh', name: 'Xhosa' },
	{ code: 'yi', name: 'Yiddish' },
	{ code: 'yo', name: 'Yoruba' },
	{ code: 'za', name: 'Zhuang, Chuang' },
	{ code: 'zu', name: 'Zulu' },
];
export const ALL_COUNTRY_CODES = [
	{ name: 'Albania', code: 'AL' },
	{ name: 'Åland Islands', code: 'AX' },
	{ name: 'Algeria', code: 'DZ' },
	{ name: 'American Samoa', code: 'AS' },
	{ name: 'Andorra', code: 'AD' },
	{ name: 'Angola', code: 'AO' },
	{ name: 'Anguilla', code: 'AI' },
	{ name: 'Antarctica', code: 'AQ' },
	{ name: 'Antigua and Barbuda', code: 'AG' },
	{ name: 'Argentina', code: 'AR' },
	{ name: 'Armenia', code: 'AM' },
	{ name: 'Aruba', code: 'AW' },
	{ name: 'Australia', code: 'AU' },
	{ name: 'Austria', code: 'AT' },
	{ name: 'Azerbaijan', code: 'AZ' },
	{ name: 'Bahamas (the)', code: 'BS' },
	{ name: 'Bahrain', code: 'BH' },
	{ name: 'Bangladesh', code: 'BD' },
	{ name: 'Barbados', code: 'BB' },
	{ name: 'Belarus', code: 'BY' },
	{ name: 'Belgium', code: 'BE' },
	{ name: 'Belize', code: 'BZ' },
	{ name: 'Benin', code: 'BJ' },
	{ name: 'Bermuda', code: 'BM' },
	{ name: 'Bhutan', code: 'BT' },
	{ name: 'Bolivia (Plurinational State of)', code: 'BO' },
	{ name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
	{ name: 'Bosnia and Herzegovina', code: 'BA' },
	{ name: 'Botswana', code: 'BW' },
	{ name: 'Bouvet Island', code: 'BV' },
	{ name: 'Brazil', code: 'BR' },
	{ name: 'British Indian Ocean Territory (the)', code: 'IO' },
	{ name: 'Brunei Darussalam', code: 'BN' },
	{ name: 'Bulgaria', code: 'BG' },
	{ name: 'Burkina Faso', code: 'BF' },
	{ name: 'Burundi', code: 'BI' },
	{ name: 'Cabo Verde', code: 'CV' },
	{ name: 'Cambodia', code: 'KH' },
	{ name: 'Cameroon', code: 'CM' },
	{ name: 'Canada', code: 'CA' },
	{ name: 'Cayman Islands (the)', code: 'KY' },
	{ name: 'Central African Republic (the)', code: 'CF' },
	{ name: 'Chad', code: 'TD' },
	{ name: 'Chile', code: 'CL' },
	{ name: 'China', code: 'CN' },
	{ name: 'Christmas Island', code: 'CX' },
	{ name: 'Cocos (Keeling) Islands (the)', code: 'CC' },
	{ name: 'Colombia', code: 'CO' },
	{ name: 'Comoros (the)', code: 'KM' },
	{ name: 'Congo (the Democratic Republic of the)', code: 'CD' },
	{ name: 'Congo (the)', code: 'CG' },
	{ name: 'Cook Islands (the)', code: 'CK' },
	{ name: 'Costa Rica', code: 'CR' },
	{ name: 'Croatia', code: 'HR' },
	{ name: 'Cuba', code: 'CU' },
	{ name: 'Curaçao', code: 'CW' },
	{ name: 'Cyprus', code: 'CY' },
	{ name: 'Czechia', code: 'CZ' },
	{ name: "Côte d'Ivoire", code: 'CI' },
	{ name: 'Denmark', code: 'DK' },
	{ name: 'Djibouti', code: 'DJ' },
	{ name: 'Dominica', code: 'DM' },
	{ name: 'Dominican Republic (the)', code: 'DO' },
	{ name: 'Ecuador', code: 'EC' },
	{ name: 'Egypt', code: 'EG' },
	{ name: 'El Salvador', code: 'SV' },
	{ name: 'Equatorial Guinea', code: 'GQ' },
	{ name: 'Eritrea', code: 'ER' },
	{ name: 'Estonia', code: 'EE' },
	{ name: 'Eswatini', code: 'SZ' },
	{ name: 'Ethiopia', code: 'ET' },
	{ name: 'Falkland Islands (the) [Malvinas]', code: 'FK' },
	{ name: 'Faroe Islands (the)', code: 'FO' },
	{ name: 'Fiji', code: 'FJ' },
	{ name: 'Finland', code: 'FI' },
	{ name: 'France', code: 'FR' },
	{ name: 'French Guiana', code: 'GF' },
	{ name: 'French Polynesia', code: 'PF' },
	{ name: 'French Southern Territories (the)', code: 'TF' },
	{ name: 'Gabon', code: 'GA' },
	{ name: 'Gambia (the)', code: 'GM' },
	{ name: 'Georgia', code: 'GE' },
	{ name: 'Germany', code: 'DE' },
	{ name: 'Ghana', code: 'GH' },
	{ name: 'Gibraltar', code: 'GI' },
	{ name: 'Greece', code: 'GR' },
	{ name: 'Greenland', code: 'GL' },
	{ name: 'Grenada', code: 'GD' },
	{ name: 'Guadeloupe', code: 'GP' },
	{ name: 'Guam', code: 'GU' },
	{ name: 'Guatemala', code: 'GT' },
	{ name: 'Guernsey', code: 'GG' },
	{ name: 'Guinea', code: 'GN' },
	{ name: 'Guinea-Bissau', code: 'GW' },
	{ name: 'Guyana', code: 'GY' },
	{ name: 'Haiti', code: 'HT' },
	{ name: 'Heard Island and McDonald Islands', code: 'HM' },
	{ name: 'Holy See (the)', code: 'VA' },
	{ name: 'Honduras', code: 'HN' },
	{ name: 'Hong Kong', code: 'HK' },
	{ name: 'Hungary', code: 'HU' },
	{ name: 'Iceland', code: 'IS' },
	{ name: 'India', code: 'IN' },
	{ name: 'Indonesia', code: 'ID' },
	{ name: 'Iran (Islamic Republic of)', code: 'IR' },
	{ name: 'Iraq', code: 'IQ' },
	{ name: 'Ireland', code: 'IE' },
	{ name: 'Isle of Man', code: 'IM' },
	{ name: 'Israel', code: 'IL' },
	{ name: 'Italy', code: 'IT' },
	{ name: 'Jamaica', code: 'JM' },
	{ name: 'Japan', code: 'JP' },
	{ name: 'Jersey', code: 'JE' },
	{ name: 'Jordan', code: 'JO' },
	{ name: 'Kazakhstan', code: 'KZ' },
	{ name: 'Kenya', code: 'KE' },
	{ name: 'Kiribati', code: 'KI' },
	{ name: "Korea (the Democratic People's Republic of)", code: 'KP' },
	{ name: 'Korea (the Republic of)', code: 'KR' },
	{ name: 'Kuwait', code: 'KW' },
	{ name: 'Kyrgyzstan', code: 'KG' },
	{ name: "Lao People's Democratic Republic (the)", code: 'LA' },
	{ name: 'Latvia', code: 'LV' },
	{ name: 'Lebanon', code: 'LB' },
	{ name: 'Lesotho', code: 'LS' },
	{ name: 'Liberia', code: 'LR' },
	{ name: 'Libya', code: 'LY' },
	{ name: 'Liechtenstein', code: 'LI' },
	{ name: 'Lithuania', code: 'LT' },
	{ name: 'Luxembourg', code: 'LU' },
	{ name: 'Macao', code: 'MO' },
	{ name: 'Madagascar', code: 'MG' },
	{ name: 'Malawi', code: 'MW' },
	{ name: 'Malaysia', code: 'MY' },
	{ name: 'Maldives', code: 'MV' },
	{ name: 'Mali', code: 'ML' },
	{ name: 'Malta', code: 'MT' },
	{ name: 'Marshall Islands (the)', code: 'MH' },
	{ name: 'Martinique', code: 'MQ' },
	{ name: 'Mauritania', code: 'MR' },
	{ name: 'Mauritius', code: 'MU' },
	{ name: 'Mayotte', code: 'YT' },
	{ name: 'Mexico', code: 'MX' },
	{ name: 'Micronesia (Federated States of)', code: 'FM' },
	{ name: 'Moldova (the Republic of)', code: 'MD' },
	{ name: 'Monaco', code: 'MC' },
	{ name: 'Mongolia', code: 'MN' },
	{ name: 'Montenegro', code: 'ME' },
	{ name: 'Montserrat', code: 'MS' },
	{ name: 'Morocco', code: 'MA' },
	{ name: 'Mozambique', code: 'MZ' },
	{ name: 'Myanmar', code: 'MM' },
	{ name: 'Namibia', code: 'NA' },
	{ name: 'Nauru', code: 'NR' },
	{ name: 'Nepal', code: 'NP' },
	{ name: 'Netherlands (the)', code: 'NL' },
	{ name: 'New Caledonia', code: 'NC' },
	{ name: 'New Zealand', code: 'NZ' },
	{ name: 'Nicaragua', code: 'NI' },
	{ name: 'Niger (the)', code: 'NE' },
	{ name: 'Nigeria', code: 'NG' },
	{ name: 'Niue', code: 'NU' },
	{ name: 'Norfolk Island', code: 'NF' },
	{ name: 'Northern Mariana Islands (the)', code: 'MP' },
	{ name: 'Norway', code: 'NO' },
	{ name: 'Oman', code: 'OM' },
	{ name: 'Pakistan', code: 'PK' },
	{ name: 'Palau', code: 'PW' },
	{ name: 'Palestine, State of', code: 'PS' },
	{ name: 'Panama', code: 'PA' },
	{ name: 'Papua New Guinea', code: 'PG' },
	{ name: 'Paraguay', code: 'PY' },
	{ name: 'Peru', code: 'PE' },
	{ name: 'Philippines (the)', code: 'PH' },
	{ name: 'Pitcairn', code: 'PN' },
	{ name: 'Poland', code: 'PL' },
	{ name: 'Portugal', code: 'PT' },
	{ name: 'Puerto Rico', code: 'PR' },
	{ name: 'Qatar', code: 'QA' },
	{ name: 'Republic of North Macedonia', code: 'MK' },
	{ name: 'Romania', code: 'RO' },
	{ name: 'Russian Federation (the)', code: 'RU' },
	{ name: 'Rwanda', code: 'RW' },
	{ name: 'Réunion', code: 'RE' },
	{ name: 'Saint Barthélemy', code: 'BL' },
	{ name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH' },
	{ name: 'Saint Kitts and Nevis', code: 'KN' },
	{ name: 'Saint Lucia', code: 'LC' },
	{ name: 'Saint Martin (French part)', code: 'MF' },
	{ name: 'Saint Pierre and Miquelon', code: 'PM' },
	{ name: 'Saint Vincent and the Grenadines', code: 'VC' },
	{ name: 'Samoa', code: 'WS' },
	{ name: 'San Marino', code: 'SM' },
	{ name: 'Sao Tome and Principe', code: 'ST' },
	{ name: 'Saudi Arabia', code: 'SA' },
	{ name: 'Senegal', code: 'SN' },
	{ name: 'Serbia', code: 'RS' },
	{ name: 'Seychelles', code: 'SC' },
	{ name: 'Sierra Leone', code: 'SL' },
	{ name: 'Singapore', code: 'SG' },
	{ name: 'Sint Maarten (Dutch part)', code: 'SX' },
	{ name: 'Slovakia', code: 'SK' },
	{ name: 'Slovenia', code: 'SI' },
	{ name: 'Solomon Islands', code: 'SB' },
	{ name: 'Somalia', code: 'SO' },
	{ name: 'South Africa', code: 'ZA' },
	{ name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
	{ name: 'South Sudan', code: 'SS' },
	{ name: 'Spain', code: 'ES' },
	{ name: 'Sri Lanka', code: 'LK' },
	{ name: 'Sudan (the)', code: 'SD' },
	{ name: 'Suriname', code: 'SR' },
	{ name: 'Svalbard and Jan Mayen', code: 'SJ' },
	{ name: 'Sweden', code: 'SE' },
	{ name: 'Switzerland', code: 'CH' },
	{ name: 'Syrian Arab Republic', code: 'SY' },
	{ name: 'Taiwan (Province of China)', code: 'TW' },
	{ name: 'Tajikistan', code: 'TJ' },
	{ name: 'Tanzania, United Republic of', code: 'TZ' },
	{ name: 'Thailand', code: 'TH' },
	{ name: 'Timor-Leste', code: 'TL' },
	{ name: 'Togo', code: 'TG' },
	{ name: 'Tokelau', code: 'TK' },
	{ name: 'Tonga', code: 'TO' },
	{ name: 'Trinidad and Tobago', code: 'TT' },
	{ name: 'Tunisia', code: 'TN' },
	{ name: 'Turkey', code: 'TR' },
	{ name: 'Turkmenistan', code: 'TM' },
	{ name: 'Turks and Caicos Islands (the)', code: 'TC' },
	{ name: 'Tuvalu', code: 'TV' },
	{ name: 'Uganda', code: 'UG' },
	{ name: 'Ukraine', code: 'UA' },
	{ name: 'United Arab Emirates (the)', code: 'AE' },
	{ name: 'United Kingdom of Great Britain and Northern Ireland (the)', code: 'GB' },
	{ name: 'United States Minor Outlying Islands (the)', code: 'UM' },
	{ name: 'United States of America (the)', code: 'US' },
	{ name: 'Uruguay', code: 'UY' },
	{ name: 'Uzbekistan', code: 'UZ' },
	{ name: 'Vanuatu', code: 'VU' },
	{ name: 'Venezuela (Bolivarian Republic of)', code: 'VE' },
	{ name: 'Viet Nam', code: 'VN' },
	{ name: 'Virgin Islands (British)', code: 'VG' },
	{ name: 'Virgin Islands (U.S.)', code: 'VI' },
	{ name: 'Wallis and Futuna', code: 'WF' },
	{ name: 'Western Sahara', code: 'EH' },
	{ name: 'Yemen', code: 'YE' },
	{ name: 'Zambia', code: 'ZM' },
	{ name: 'Zimbabwe', code: 'ZW' },
];

export const DEFAULT_LANGUAGE_LOCALE = 'nl';
export const DEFAULT_MARKETING_LANGUAGE_LOCALE = 'en';

export const IS_PRODUCTION = !['staging', 'localhost'].some((element) =>
	FRONTEND_URL.includes(element),
);
